<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-checkbox-group v-model="d_agreedList" stacked switches style="border-radius: 5px; border: solid 1px #a0d0bc; padding: 5px;">
          <template v-for="missing in p_missingAgreement">
            <b-row>
              <b-col cols="12">
                <b-form-checkbox :name="missing" :value="missing">
                  <span @click="d_showAgreement.agreement = missing; d_showAgreement.show = true;" style="cursor: pointer; text-decoration: underline;">
                {{ d_wisdomEraSystem.agreement[missing].label }}</span>, okudum onaylıyorum
                </b-form-checkbox>
              </b-col>
            </b-row>
          </template>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button :disabled="p_missingAgreement.length > d_agreedList.length" block variant="success" @click="f_agreeAgreement()">Metinleri Kabul Et</b-button>
      </b-col>
    </b-row>
    <modal v-if="d_showAgreement.show" @close="d_showAgreement.show = false" :width="'500'">
      <h3 slot="header"> Agreement Detail </h3>
      <div slot="body" v-html="d_agreements[d_showAgreement.agreement].version[f_calculateLastVersion(d_showAgreement.agreement)]">
        {{ d_agreements[d_showAgreement.agreement].version[f_calculateLastVersion(d_showAgreement.agreement)] }}
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showAgreement.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import UserService from '@/services/users';

export default {
  name: 'AgreementModal',
  props: {
    p_missingAgreement: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    p_showThisModal: {
      type: Object,
      required: true,
      default () {
        return { 'show': true }
      }
    }
  },
  data () {
    return {
      d_agreements: require('@/options/agreements').agreements,
      d_wisdomEraSystem: {},
      user: {},
      d_showAgreement: { 'agreement': '', 'show': false },
      d_agreedList: []
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.d_wisdomEraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
  },
  mounted () {},
  methods: {
    f_calculateLastVersion: function (agreement_value) {
      return this.d_wisdomEraSystem.agreement[agreement_value].list[this.d_wisdomEraSystem.agreement[agreement_value].list.length - 1].version;
    },
    f_agreeAgreement: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sözleşmeler onaylanıyor' } });
      let data = {};
      data.agreement_list = [];
      for (let i in this.d_agreedList) {
        let agreement_value = this.d_agreedList[i];
        let last_version = this.d_wisdomEraSystem.agreement[agreement_value].list[this.d_wisdomEraSystem.agreement[agreement_value].list.length - 1];
        let push_data = { 'value': agreement_value, 'date': last_version.date, 'version': last_version.version }
        data.agreement_list.push(push_data);
      }
      if (this.p_showThisModal.type === 'user') {
        UserService.agree_agreement(data)
          .then(resp => {
            let tmp_user = JSON.parse(localStorage.getItem("user"));
            tmp_user.agreement = resp.data.result;
            localStorage.setItem('user', JSON.stringify(tmp_user));
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.p_showThisModal.show = false;
          });
      } else {
        let d_date = new Date();
        let local_agreement = {}
        if (localStorage.getItem('agreement')) {
          local_agreement = JSON.parse(localStorage.getItem('agreement'));
        }
        for (let i in data.agreement_list) {
          let agreement = data.agreement_list[i];
          // console.log(agreement);
          if (!local_agreement[agreement.value]) {
            local_agreement[agreement.value] = { 'list': [] };
          }
          local_agreement[agreement.value].list.push({ 'agreed_date': d_date.toISOString(), 'agreement': { 'date': agreement.date, 'version': agreement.version, 'value': agreement.value } });
        }
        localStorage.setItem('agreement', JSON.stringify(local_agreement));
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        this.p_showThisModal.show = false;
      }
    }
  },
  watch: {},
  components: {
    Modal: () =>
      import ('@/components/widgets/Modal')
  }
};

</script>

<style>


</style>

